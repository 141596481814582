$colors: (
  'primary-black': #000000,
  'primary-blue': #408bfb,
  'primary-green': #13c324,
  'primary-orange': #ffaa04,
  'primary-pink': #f59ec0,
  'primary-purple': #d868ea,
  'primary-red': #ff5348,
  'primary-turquoise': #13c3b8,
  'primary-yellow': #ffe500,
  'secondary-beige': #f9f7f3,
  'secondary-blue-dark': #0066ff,
  'secondary-grey': #a4a0a0,
  'secondary-grey-dark': #717171,
  'secondary-grey-light': #c4c4c4,
  'secondary-grey-pale': #e9e9e9,
  'secondary-white': #ffffff,
);

$transitions: (
  'default': 0.3s ease-out,
  'fast': 0.2s ease-out,
);

$border-radius: (
  'default': 4px,
  'small': 8px,
  'medium': 16px,
  'large': 20px,
);

$breakpoints: (
  'default': 0px,
  'small': 480px,
  'medium': 768px,
  'large': 1024px,
  'xlarge': 1440px,
  'xxlarge': 1920px,
);

$font-weights: (
  'regular': 400,
  'semi-bold': 600,
  'bold': 700,
  400: 400,
  600: 600,
  700: 700,
);

$grid-max-width: 860px;

$grid-column-count: 12;

$grid-margins: (
  'default': 20px,
  'small': 24px,
  'medium': 100px,
  'large': 100px,
  'xlarge': 200px,
);

$grid-column-gutters: (
  'default': 15px,
  'small': 32px,
  'medium': 64px,
  'xlarge': 128px,
);

$spacings: (
  'xxl': 64px,
  'xl': 32px,
  'l': 24px,
  'm': 16px,
  's': 12px,
  'xs': 8px,
  'xxs': 4px,
  'xxxs': 2px,
);

$module-spacings: (
  's': 80px,
  'm': 120px,
  'l': 180px,
  'xl': 300px,
);
